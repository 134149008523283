<template>
  <div>
    <loading
      :active.sync="isLoading"
      color="#f8a229"
      loader="dots"
      :width="loadingSize"
      :height="loadingSize"
      :is-full-page="loadingFullPage"
    />
    <v-card-text>
      <!-- <v-container class="row1">
        <v-row>
          <v-col cols="14" sm="8" md="8">
            <v-row dense class="mb-5">
              <router-link
                v-bind:to="{
                  path: '/products-category/' + productInfo.subDepartmentId
                }"
                style="text-decoration: none;"
              >
                <v-btn rounded color="#05a2fa">
                  <v-icon color="white">
                    mdi-arrow-left
                  </v-icon>
                </v-btn>
              </router-link>
            </v-row>
          </v-col>
        </v-row>
      </v-container> -->

      <v-container class="mt-n12">
        <v-row>
          <v-col cols="14" sm="12" md="12" class="mx-auto">
            <v-card>
              <v-col>
                <v-row
                  style="float: right; margin-top: 5px; margin-right: 10px"
                >
                  <v-tooltip top color="black">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="secondary"
                        @click="editItem()"
                        style="margin-right: 10px"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-circle-edit-outline
                      </v-icon>
                    </template>
                    <span>Edit {{ productInfo.productName }}</span>
                  </v-tooltip>

                  <v-tooltip top color="black">
                    <template v-slot:activator="{ on, attrs }">
                      <div>
                        <v-icon
                          color="red"
                          @click="deleteItem()"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-delete-circle
                        </v-icon>
                      </div>
                    </template>
                    <span>Delete {{ productInfo.productName }}</span>
                  </v-tooltip>
                </v-row>
              </v-col>

              <v-container class="mb-n10">
                <v-img
                  :src="$url + productInfo.itemPic"
                  width="150px"
                  height="150px"
                  style="
                    left: 50%;
                    border-radius: 5%;
                    transform: translateX(-50%);
                  "
                >
                </v-img>
              </v-container>
              <v-col class="mb-n10">
                <v-card-title
                  class="justify-center font-weight-bold"
                  style="font-size: 20px"
                >
                  {{ productInfo.productName }}
                </v-card-title>
              </v-col>
              <v-card-text>
                <v-tabs grow>
                  <v-tab class="text-capitalize text-lg font-weight-bold"
                    >Overview</v-tab
                  >
                  <v-tab-item style="background-color: rgb(238, 238, 238)">
                    <v-card>
                      <v-container>
                        <div style="color: black; text-align: left">
                          <v-card-text>
                            <v-col md="12">
                              <v-row>
                                <v-col md="10">
                                  <div class="text-h5">
                                    Department :

                                    <router-link
                                      v-bind:to="{
                                        path:
                                          '/admin/admin-departments/' +
                                          productInfo.department.departmentId
                                      }"
                                      style="
                                        text-decoration: none;
                                        cursor: pointer;
                                      "
                                    >
                                      <v-chip
                                        style="cursor: pointer"
                                        label
                                        color="secondary"
                                        class="font-weight-bold ml-2"
                                        text-color="black"
                                      >
                                        {{
                                          productInfo.department
                                            .departmentName || "Null"
                                        }}
                                      </v-chip>
                                    </router-link>
                                  </div>
                                  <v-divider class="mb-2 mt-2"></v-divider>
                                  <div class="text-h5">
                                    Category :
                                    <router-link
                                      v-bind:to="{
                                        path:
                                          '/admin/admin-departments/admin-sub-departments/' +
                                          productInfo.subDepartment
                                            .subDepartmentId
                                      }"
                                      style="text-decoration: none"
                                    >
                                      <v-chip
                                        style="cursor: pointer"
                                        label
                                        color="secondary"
                                        class="font-weight-bold ml-2"
                                        text-color="black"
                                      >
                                        {{
                                          productInfo.subDepartment
                                            .subDepartmentName || "Null"
                                        }}
                                      </v-chip>
                                    </router-link>
                                  </div>
                                  <v-divider class="mb-2 mt-2"></v-divider>
                                  <div
                                    v-if="productInfo.unitId"
                                    class="text-h5"
                                  >
                                    Unit :
                                    <router-link
                                      v-bind:to="{
                                        path:
                                          '/admin/admin-units/' +
                                          productInfo.unit.unitID
                                      }"
                                      style="text-decoration: none"
                                    >
                                      <v-chip
                                        style="cursor: pointer"
                                        label
                                        color="secondary"
                                        class="font-weight-bold ml-2"
                                        text-color="black"
                                      >
                                        {{
                                          productInfo.unit.unitName || "Null"
                                        }}
                                      </v-chip>
                                    </router-link>
                                  </div>
                                  <v-divider class="mb-2 mt-2"></v-divider>
                                  <div class="text-h5">
                                    Description :

                                    <v-chip
                                      label
                                      color="secondary"
                                      class="font-weight-bold ml-2"
                                      text-color="black"
                                      v-if="productInfo.productDescription"
                                    >
                                      {{ productInfo.productDescription }}
                                    </v-chip>
                                  </div>

                                  <v-divider class="mb-2 mt-2"></v-divider>

                                  <div class="text-h5">
                                    Price Info :
                                    <v-chip
                                      label
                                      color="secondary"
                                      class="font-weight-bold ml-2 mb-2"
                                      text-color="black"
                                    >
                                      Retail : $
                                      {{
                                        formatPrice(productInfo.retailPrice) ||
                                          "Null"
                                      }}</v-chip
                                    >

                                    <!-- <v-chip
                                      label
                                      color="secondary"
                                      class="font-weight-bold ml-2 mb-2"
                                      text-color="black"
                                    >
                                      Pick Up : $
                                      {{
                                        formatPrice(
                                          productInfo.takeawayPrice
                                        ) || "Null"
                                      }}</v-chip
                                    >

                                    <v-chip
                                      label
                                      color="secondary"
                                      class="font-weight-bold ml-2 mb-2"
                                      text-color="black"
                                    >
                                      Dine : $
                                      {{
                                        formatPrice(productInfo.dinePrice) ||
                                          "Null"
                                      }}</v-chip
                                    >

                                    <v-chip
                                      label
                                      color="secondary"
                                      class="font-weight-bold ml-2 mb-2"
                                      text-color="black"
                                    >
                                      Delivery : $
                                      {{
                                        formatPrice(
                                          productInfo.deliveryPrice
                                        ) || "Null"
                                      }}</v-chip
                                    > -->
                                  </div>
                                  <v-divider class="mb-2 mt-2"></v-divider>
                                  <div class="text-h5">
                                    Vat Percentage :

                                    <v-chip
                                      label
                                      color="secondary"
                                      class="font-weight-bold ml-2"
                                      text-color="black"
                                    >
                                      {{
                                        productInfo.vatPercentage + "%" ||
                                          "Null"
                                      }}
                                    </v-chip>
                                  </div>
                                  <v-divider
                                    class="mb-2 mt-2"
                                  ></v-divider> </v-col
                                ><v-col md="2">
                                  <div class="text-h5">
                                    <qrcode-vue
                                      render-as="canvas"
                                      :value="productInfo.barcode"
                                      size="128"
                                      level="H"
                                    />
                                  </div>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-card-text></div></v-container
                    ></v-card>
                  </v-tab-item>
                  <!-- <v-tab class="text-capitalize font-weight-bold">
                    Add Ons</v-tab
                  >
                  <v-tab-item style="background-color: rgb(238, 238, 238)">
                    <v-card>
                      <v-container>
                        <div style="color: black; text-align: center">
                          <v-btn
                            outlined
                            color="secondary"
                            dark
                            @click="addAddOn"
                          >
                            <v-icon color="black"> mdi-plus-circle </v-icon>
                            <v-card-text class="black--text font-weight-bold">
                              Add Add On
                            </v-card-text>
                          </v-btn>
                        </div>
                        <div style="color: black; text-align: center">
                          <v-container>
                            <v-card-text>
                              <v-col>
                                <v-chip
                                  v-for="addon in productInfo.productAddons"
                                  :key="addon.productIngredientId"
                                  label
                                  color="secondary"
                                  class="font-weight-bold mb-2 ml-2"
                                  text-color="black"
                                  close
                                  close-icon="mdi-close-circle"
                                  @click:close="deleteAddon(addon)"
                                  >{{ addon.addon.addonName }}</v-chip
                                >
                              </v-col>
                            </v-card-text>
                          </v-container>
                        </div>
                      </v-container>
                    </v-card>
                    <v-tooltip top color="black">
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          style="
                            margin-top: 0px;
                            float: right;
                            background-color: white;
                            border-radius: 0px 10px 10px 0px;
                          "
                        >
                          <v-btn
                            color="secondary"
                            @click="dialogTrash = true"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon color="black"> mdi-delete-restore </v-icon>
                          </v-btn>
                        </div>
                      </template>
                      <span>Trash </span>
                    </v-tooltip>
                  </v-tab-item>
                  <v-tab class="text-capitalize font-weight-bold"
                    >Ingredients
                  </v-tab>
                  <v-tab-item style="background-color: rgb(238, 238, 238)">
                    <v-card>
                      <v-container>
                        <div style="color: black; text-align: center">
                          <v-btn
                            outlined
                            color="secondary"
                            dark
                            @click="addIngredient"
                          >
                            <v-icon color="black"> mdi-plus-circle </v-icon>
                            <v-card-text class="black--text font-weight-bold">
                              Add Ingredient
                            </v-card-text>
                          </v-btn>
                        </div>
                        <div style="color: black; text-align: center">
                          <v-container>
                            <v-card-text>
                              <v-col>
                                <v-chip
                                  v-for="ingredient in productInfo.productIngredients"
                                  :key="ingredient.productIngredientId"
                                  label
                                  color="secondary"
                                  class="font-weight-bold mb-2 ml-2"
                                  text-color="black"
                                  close
                                  close-icon="mdi-close-circle"
                                  @click:close="deleteIngredient(ingredient)"
                                  >{{
                                    ingredient.ingredient.ingredientName
                                  }}</v-chip
                                >
                              </v-col>
                            </v-card-text>
                          </v-container>
                        </div>
                      </v-container>
                    </v-card>
                    <v-tooltip top color="black">
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          style="
                            margin-top: 0px;
                            float: right;
                            background-color: white;
                            border-radius: 0px 10px 10px 0px;
                          "
                        >
                          <v-btn
                            color="secondary"
                            @click="dialogTrash = true"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon color="black"> mdi-delete-restore </v-icon>
                          </v-btn>
                        </div>
                      </template>
                      <span>Trash </span>
                    </v-tooltip>
                  </v-tab-item>

                  <v-tab class="text-capitalize font-weight-bold">Sizes </v-tab>
                  <v-tab-item style="background-color: rgb(238, 238, 238)">
                    <v-card>
                      <v-container>
                        <div style="color: black; text-align: center">
                          <v-btn
                            outlined
                            color="secondary"
                            dark
                            @click="addSize"
                          >
                            <v-icon color="black"> mdi-plus-circle </v-icon>
                            <v-card-text class="black--text font-weight-bold">
                              Add Size
                            </v-card-text>
                          </v-btn>
                        </div>
                        <div style="color: black; text-align: center">
                          <v-container>
                            <v-card-text>
                              <v-col>
                                <v-chip
                                  v-for="size in productInfo.productSizes"
                                  :key="size.productSizeId"
                                  label
                                  color="secondary"
                                  class="font-weight-bold mb-2 ml-2"
                                  text-color="black"
                                  close
                                  close-icon="mdi-close-circle"
                                  @click:close="deleteSize(size)"
                                  >{{ size.size.sizeName }}</v-chip
                                >
                              </v-col>
                            </v-card-text>
                          </v-container>
                        </div>
                      </v-container>
                    </v-card>
                    <v-tooltip top color="black">
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          style="
                            margin-top: 0px;
                            float: right;
                            background-color: white;
                            border-radius: 0px 10px 10px 0px;
                          "
                        >
                          <v-btn
                            color="secondary"
                            @click="dialogTrash = true"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon color="black"> mdi-delete-restore </v-icon>
                          </v-btn>
                        </div>
                      </template>
                      <span>Trash </span>
                    </v-tooltip>
                  </v-tab-item>

                  <v-tab class="text-capitalize font-weight-bold"
                    >Options
                  </v-tab>
                  <v-tab-item style="background-color: rgb(238, 238, 238)">
                    <v-card>
                      <v-container>
                        <div style="color: black; text-align: center">
                          <v-btn
                            outlined
                            color="secondary"
                            dark
                            @click="addOption"
                          >
                            <v-icon color="black"> mdi-plus-circle </v-icon>
                            <v-card-text class="black--text font-weight-bold">
                              Add Option
                            </v-card-text>
                          </v-btn>
                        </div>
                        <div style="color: black; text-align: center">
                          <v-container>
                            <v-card-text>
                              <v-col>
                                <v-chip
                                  v-for="option in productInfo.productOptions"
                                  :key="option.productOptionId"
                                  label
                                  color="secondary"
                                  class="font-weight-bold mb-2 ml-2"
                                  text-color="black"
                                  close
                                  close-icon="mdi-close-circle"
                                  @click:close="deleteOption(option)"
                                  >{{ option.option.optionName }}</v-chip
                                >
                              </v-col>
                            </v-card-text>
                          </v-container>
                        </div>
                      </v-container>
                    </v-card>
                    <v-tooltip top color="black">
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          style="
                            margin-top: 0px;
                            float: right;
                            background-color: white;
                            border-radius: 0px 10px 10px 0px;
                          "
                        >
                          <v-btn
                            color="secondary"
                            @click="dialogTrash = true"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon color="black"> mdi-delete-restore </v-icon>
                          </v-btn>
                        </div>
                      </template>
                      <span>Trash </span>
                    </v-tooltip>
                  </v-tab-item>

                  <v-tab class="text-capitalize font-weight-bold"
                    >Instruction
                  </v-tab>
                  <v-tab-item style="background-color: rgb(238, 238, 238)">
                    <v-card>
                      <v-container>
                        <div style="color: black; text-align: center">
                          <v-btn
                            outlined
                            color="secondary"
                            dark
                            @click="addInstruction"
                          >
                            <v-icon color="black"> mdi-plus-circle </v-icon>
                            <v-card-text class="black--text font-weight-bold">
                              Add Instruction
                            </v-card-text>
                          </v-btn>
                        </div>
                        <div style="color: black; text-align: center">
                          <v-container>
                            <v-card-text>
                              <v-col>
                                <v-chip
                                  v-for="instruction in productInfo.productInstructions"
                                  :key="instruction.productInstructionId"
                                  label
                                  color="secondary"
                                  class="font-weight-bold mb-2 ml-2"
                                  text-color="black"
                                  close
                                  close-icon="mdi-close-circle"
                                  @click:close="deleteInstruction(instruction)"
                                  >{{ instruction.instruction.note }}</v-chip
                                >
                              </v-col>
                            </v-card-text>
                          </v-container>
                        </div>
                      </v-container>
                    </v-card>
                    <v-tooltip top color="black">
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          style="
                            margin-top: 0px;
                            float: right;
                            background-color: white;
                            border-radius: 0px 10px 10px 0px;
                          "
                        >
                          <v-btn
                            color="secondary"
                            @click="dialogTrash = true"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon color="black"> mdi-delete-restore </v-icon>
                          </v-btn>
                        </div>
                      </template>
                      <span>Trash </span>
                    </v-tooltip>
                  </v-tab-item> -->

                </v-tabs>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <v-dialog v-model="dialogEdit" max-width="500px" origin="top right">
        <v-card>
          <v-row style="float: right; margin-top: 10px; margin-right: 10px">
            <div>
              <v-icon color="closeButton" @click="dialogEdit = false">
                mdi-close-circle
              </v-icon>
            </div>
          </v-row>
          <v-card-title>
            <span class="headline">Edit Product</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-form ref="editedForm">
                <v-row>
                  <v-col>
                    <v-col cols="12" class="mb-n8">
                      <v-select
                        v-model="editedProductInfo.subDepartmentId"
                        label="Sub Department"
                        :items="subDepartments"
                        item-text="subDepartmentName"
                        item-value="subDepartmentId"
                        outlined
                        clearable
                      />
                    </v-col>

                    <v-col cols="12" class="mb-n8">
                      <v-select
                        v-model="editedProductInfo.unitId"
                        label="Unit"
                        :items="units"
                        item-text="unitName"
                        item-value="unitID"
                        outlined
                        clearable
                      />
                    </v-col>

                    <v-col cols="12" class="mb-n8">
                      <v-text-field
                        type="number"
                        class="numberInput"
                        v-model="editedProductInfo.barcode"
                        label="EAN"
                        outlined
                        clearable
                        maxlength="13"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      />
                    </v-col>

                    <v-col cols="12" class="mb-n8">
                      <v-text-field
                        v-model="editedProductInfo.productName"
                        label="Product Name"
                        outlined
                        clearable
                      />
                    </v-col>
                    <v-col cols="12" class="mb-n8">
                      <v-text-field
                        v-model="editedProductInfo.productDescription"
                        label="Product Description"
                        outlined
                        clearable
                      />
                    </v-col>
                  </v-col>

                  <!-- <v-col>
                    <v-col cols="12" class="mb-n8">
                      <v-text-field
                        type="number"
                        class="numberInput"
                        v-model="editedProductInfo.retailPrice"
                        label="Price"
                        outlined
                        clearable
                      />
                    </v-col>

                    <v-col cols="12" class="mb-n8">
                      <v-text-field
                        type="number"
                        class="numberInput"
                        v-model="editedProductInfo.discountPrice"
                        label="Discount Price"
                        outlined
                        clearable
                      />
                    </v-col>
                  </v-col> -->
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="dialogEdit = false">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="saveEdit"> Save </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="headline">
            Are you sure you want to delete this item?
          </v-card-title>
          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="closeDelete">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="trashItem"> OK </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="addAddOnView" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">Add AddOn</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-form ref="AddOnForm">
                <v-row>
                  <!-- <v-col cols="12" class="mb-n8">
                    <v-text-field
                      v-model="addOnInfo.productId"
                      label="Product Id"
                      :items="productInfo"
                      outlined
                      clearable
                    />
                  </v-col> -->

                  <v-col cols="12" class="mb-n8">
                    <v-select
                      v-model="addOnInfo.unitId"
                      label="Units"
                      :items="units"
                      item-text="unitName"
                      item-value="unitID"
                      outlined
                      clearable
                    />
                  </v-col>
                  <v-col cols="12" class="mb-n8">
                    <v-select
                      v-model="addOnInfo.addonId"
                      label="AddOn"
                      :items="addons"
                      item-text="addonName"
                      item-value="addonId"
                      outlined
                      clearable
                    />
                  </v-col>
                  <!-- <v-col cols="12" class="mb-n8">
                    <v-text-field
                      v-model="addOnInfo.retailPrice"
                      label="Retail Price"
                      outlined
                      clearable
                    />
                  </v-col> -->
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="addAddOnView = false">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="addOnConfirm()">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="addIngredientView" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">Add Ingredient</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-form ref="IngredientForm">
                <v-row>
                  <!-- <v-col cols="12" class="mb-n8">
                    <v-text-field
                      v-model="ingredientInfo.productId"
                      label="Product Id"
                      :items="productInfo"
                      outlined
                      clearable
                    />
                  </v-col> -->

                  <v-col cols="12" class="mb-n8">
                    <v-select
                      v-model="ingredientInfo.ingredientId"
                      label="Ingredient"
                      :items="ingredients"
                      item-text="ingredientName"
                      item-value="ingredientId"
                      outlined
                      clearable
                    />
                  </v-col>
                  <v-col cols="12" class="mb-n8">
                    <v-text-field
                      v-model="ingredientInfo.description"
                      label="Description"
                      outlined
                      clearable
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="blue darken-1"
              text
              @click="addIngredientView = false"
            >
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="ingredientConfirm">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="addSizeView" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">Add Size</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-form ref="SizeForm">
                <v-row>
                  <!-- <v-col cols="12" class="mb-n8">
                    <v-text-field
                      v-model="sizeInfo.productId"
                      label="Product Id"
                      :items="productInfo"
                      outlined
                      clearable
                    />
                  </v-col> -->

                  <v-col cols="12" class="mb-n8">
                    <v-select
                      v-model="sizeInfo.sizeId"
                      label="Size"
                      :items="sizes"
                      item-text="sizeName"
                      item-value="sizeId"
                      outlined
                      clearable
                    />
                  </v-col>
                  <v-col cols="12" class="mb-n8">
                    <v-text-field
                      v-model="sizeInfo.price"
                      label="Price"
                      outlined
                      clearable
                    />
                  </v-col>
                  <v-col cols="12" class="mb-n8">
                    <v-text-field
                      v-model="sizeInfo.dineInPrice"
                      label="Dine In Price"
                      outlined
                      clearable
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="addSizeView = false">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="sizeConfirm">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="addOptionView" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">Add Option</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-form ref="OptionForm">
                <v-row>
                  <v-col cols="12" class="mb-n8">
                    <v-select
                      v-model="optionInfo.unitId"
                      label="Units"
                      :items="units"
                      item-text="unitName"
                      item-value="unitID"
                      outlined
                      clearable
                    />
                  </v-col>
                  <!-- <v-col cols="12" class="mb-n8">
                    <v-text-field
                      v-model="optionInfo.productId"
                      label="Product Id"
                      :items="productInfo"
                      outlined
                      clearable
                    />
                  </v-col> -->

                  <v-col cols="12" class="mb-n8">
                    <v-select
                      v-model="optionInfo.optionId"
                      label="Option"
                      :items="options"
                      item-text="optionName"
                      item-value="optionId"
                      outlined
                      clearable
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="addOptionView = false">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="optionConfirm">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="addInstructionView" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">Add Instruction</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-form ref="InstructionForm">
                <v-row>
                  <!-- <v-col cols="12" class="mb-n8">
                    <v-text-field
                      v-model="instructionInfo.productId"
                      label="Product Id"
                      :items="productInfo"
                      outlined
                      clearable
                    />
                  </v-col> -->

                  <v-col cols="12" class="mb-n8">
                    <v-select
                      v-model="instructionInfo.instructionId"
                      label="Instruction"
                      :items="instructions"
                      item-text="note"
                      item-value="instructionId"
                      outlined
                      clearable
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="blue darken-1"
              text
              @click="addInstructionView = false"
            >
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="instructionConfirm">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
  </div>
</template>

<style>
.row1 {
  margin-top: -25px;
}
.inputNumber input[type="number"] {
  -moz-appearance: textfield;
}
.inputNumber input::-webkit-outer-spin-button,
.inputNumber input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import MaterialCard from "../components/MaterialCard.vue";

import QrcodeVue from "qrcode.vue";
import Vue from "vue";
Vue.component("qrcode-vue", QrcodeVue);

export default {
  components: { MaterialCard, Loading },
  data() {
    return {
      isLoading: false,
      loadingSize: 50,
      loadingFullPage: true,
      loading: false,
      dialogEdit: false,
      dialogDelete: false,
      dialogRestore: false,
      dialogTrash: false,
      search: "",

      status: [
        { text: "Active", value: true },
        { text: "InActive", value: false }
      ],

      editedIndex: -1,

      editedProductInfo: {
        productName: "",
        productDescription: "",
        ean: "",
        sku: "",
        isOutOfStock: true,
        available: true,
        price: "",
        sale: true,
        salePrice: "",
        subDepartmentId: "",
        locationId: 1
      },

      productInfo: {},
      units: [],
      subDepartments: [],
      addAddOnView: false,
      addons: [],
      addOnInfo: {
        retailPrice: 0,
        cost: 0,
        quantity: 0,
        image: "string",
        productId: 0,
        addonId: 0,
        unitId: 0
      },
      defaultAddOnInfo: {
        retailPrice: 0,
        cost: 0,
        quantity: 0,
        image: "string",
        productId: 0,
        addonId: 0,
        unitId: 0
      },

      addIngredientView: false,
      ingredients: [],
      ingredientInfo: {
        description: "",
        quantity: 0,
        productId: 0,
        ingredientId: 0
      },
      defaultIngredientInfo: {
        description: "",
        quantity: 0,
        productId: 0,
        ingredientId: 0
      },
      addSizeView: false,
      sizes: [],
      sizeInfo: {
        productId: 0,
        sizeId: 0,
        price: ""
      },
      defaultSizeInfo: {
        productId: 0,
        sizeId: 0,
        price: ""
      },

      addOptionView: false,
      options: [],
      optionInfo: {},
      defaultOptionInfo: {},

      addInstructionView: false,
      instructions: [],
      instructionInfo: {},
      defaultInstructionInfo: {}
    };
  },

  watch: {
    dialogEdit(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    }
  },

  created() {
    this.getDataFromApi();
  },
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    getDataFromApi() {
      this.isLoading = true;
      var currentUrl = window.location.pathname;
      let vars = currentUrl.split("/");
      vars.reverse();
      let itemId = vars[0];
      itemId = parseInt(itemId);

      this.$http.get(`/ProductMaster/${itemId}`).then(response => {
        this.productInfo = response.data.data;
        console.log("productInfo", response);
        console.log("productInfo", this.productInfo);
        this.isLoading = false;
      });

      this.$http.get("/Unites/").then(response => {
        this.units = response.data.data;
      });
      this.$http.get("/Menus/").then(response => {
        this.subDepartments = response.data;
      });

      this.$http.get("/Addon/").then(response => {
        this.addons = response.data.data;
      });
      this.$http.get("/Ingredient/").then(response => {
        this.ingredients = response.data.data;
      });
      this.$http.get("/Size/").then(response => {
        this.sizes = response.data.data;
      });
      this.$http.get("/Option/").then(response => {
        this.options = response.data.data;
      });
      this.$http.get("/Instruction/").then(response => {
        this.instructions = response.data.data;
        console.log("Ins", this.instructions);
      });
    },

    editItem() {
      this.editedProductInfo = Object.assign({}, this.productInfo);
      this.dialogEdit = true;
    },

    deleteItem() {
      this.dialogDelete = true;
    },

    trashItem() {
      var id = this.productInfo.subDepartmentId;
      this.$http
        .post("/ProductMaster/Trash", this.productInfo)
        .then(response => {
          if (response.data.success === true) {
            this.$toast.success("Deleted successfully.", "Success", {
              position: "topRight"
            });
            this.$router.push(`/products-category/${id}`);
          } else {
            this.$toast.error(response.data.message, "Error", {
              position: "topRight"
            });
          }
        })
        .catch(error => {
          if (error.response.status) {
            this.$toast.error(" Deleting Process Failed", "Error", {
              position: "topRight"
            });
          }
        });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedProductInfo = Object.assign({}, this.defaultProduct);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedProductInfo = Object.assign({}, this.defaultProduct);
        this.editedIndex = -1;
      });
    },

    saveEdit() {
      //this.productInfo ="";
      this.isLoading = true;
      var Id = this.productInfo.productId;
      this.$http
        .put("/ProductMaster/" + Id, this.editedProductInfo)
        .then(response => {
          if (response.data.success === true) {
            //this.productInfo = Object.assign({}, response.data.data);
            //  console.log(this.productInfo);

            this.$http.get(`/ProductMaster/${Id}`).then(response => {
              this.productInfo = response.data.data;

              this.isLoading = false;
            });

            this.$toast.success("Product updated successfully.", "Success", {
              position: "topRight"
            });
          } else {
            this.$toast.error(response.data.message, "Error", {
              position: "topRight"
            });
          }
        })
        .catch(error => {
          if (error.response.status) {
            this.$toast.error(" Updating Process Failed", "Error", {
              position: "topRight"
            });
          }
        });
      this.dialogEdit = false;
    },
    addAddOn() {
      this.addOnInfo = Object.assign({}, this.defaultAddOnInfo);
      this.addOnInfo.productId = this.productInfo.productId;

      this.addOnInfo.unitId = this.productInfo.unitId;
      this.addAddOnView = true;
    },
    addOnConfirm() {
      this.$http
        .post("/ProductAddons", this.addOnInfo)
        .then(response => {
          if (response.data.success === true) {
            console.log(this.addOnInfo);

            this.$toast.success("Add On added successfully.", "Success", {
              position: "topRight"
            });
            this.getDataFromApi();
          } else {
            this.$toast.error(response.data.message, "Error", {
              position: "topRight"
            });
          }
        })
        .catch(error => {
          if (error.response.status) {
            this.$toast.error(" Updating Process Failed", "Error", {
              position: "topRight"
            });
          }
        });
      this.addAddOnView = false;
    },
    addIngredient() {
      this.ingredientInfo = Object.assign({}, this.defaultIngredientInfo);
      this.ingredientInfo.productId = this.productInfo.productId;
      this.addIngredientView = true;
    },
    ingredientConfirm() {
      console.log(this.ingredientInfo);
      this.$http
        .post("/ProductIngredient", this.ingredientInfo)
        .then(response => {
          if (response.data.success === true) {
            console.log(this.ingredientInfo);
            this.$toast.success("Ingredient added successfully.", "Success", {
              position: "topRight"
            });
            this.getDataFromApi();
          } else {
            this.$toast.error(response.data.message, "Error", {
              position: "topRight"
            });
          }
        })
        .catch(error => {
          if (error.response.status) {
            this.$toast.error(" Updating Process Failed", "Error", {
              position: "topRight"
            });
          }
        });
      this.addIngredientView = false;
    },
    addSize() {
      this.sizeInfo = Object.assign({}, this.defaultSizeInfo);
      this.sizeInfo.productId = this.productInfo.productId;
      this.addSizeView = true;
    },

    sizeConfirm() {
      console.log(this.sizeInfo);
      if (this.sizeInfo.price) {
        this.$http
          .post("/ProductSize", this.sizeInfo)
          .then(response => {
            if (response.data.success === true) {
              console.log(response);
              this.$toast.success("Size added successfully.", "Success", {
                position: "topRight"
              });
              this.getDataFromApi();
            } else {
              this.$toast.error(response.data.message, "Error", {
                position: "topRight"
              });
            }
          })
          .catch(error => {
            if (error.response.status) {
              this.$toast.error(" Updating Process Failed", "Error", {
                position: "topRight"
              });
            }
          });
        this.addSizeView = false;
      } else {
        this.$toast.error("Price is required", "Error", {
          position: "topRight"
        });
      }
    },

    deleteSize(size) {
      console.log(size);
    },

    addOption() {
      this.optionInfo = Object.assign({}, this.defaultOptionInfo);
      this.optionInfo.productId = this.productInfo.productId;
      this.optionInfo.unitId = this.productInfo.unitId;
      this.addOptionView = true;
    },
    optionConfirm() {
      console.log(this.optionInfo);
      this.$http
        .post("/ProductOption", this.optionInfo)
        .then(response => {
          if (response.data.success === true) {
            console.log(response);
            this.$toast.success("Option added successfully.", "Success", {
              position: "topRight"
            });
            this.getDataFromApi();
          } else {
            this.$toast.error(response.data.message, "Error", {
              position: "topRight"
            });
          }
        })
        .catch(error => {
          if (error.response.status) {
            this.$toast.error(" Updating Process Failed", "Error", {
              position: "topRight"
            });
          }
        });
      this.addOptionView = false;
    },

    addInstruction() {
      this.instructionInfo = Object.assign({}, this.defaultInstructionInfo);
      this.instructionInfo.productId = this.productInfo.productId;
      this.instructionInfo.unitId = this.productInfo.unitId;
      this.addInstructionView = true;
    },
    instructionConfirm() {
      console.log(this.instructionInfo);
      this.$http
        .post("/ProductInstruction", this.instructionInfo)
        .then(response => {
          if (response.data.success === true) {
            console.log(response);
            this.$toast.success("Instruction added successfully.", "Success", {
              position: "topRight"
            });
            this.getDataFromApi();
          } else {
            this.$toast.error(response.data.message, "Error", {
              position: "topRight"
            });
          }
        })
        .catch(error => {
          if (error.response.status) {
            this.$toast.error(" Updating Process Failed", "Error", {
              position: "topRight"
            });
          }
        });
      this.addInstructionView = false;
    },

    getStatusColor(data) {
      switch (data) {
        case "true":
          return "success";
        case "false":
          return "error";
        default:
          return "";
      }
    }
  }
};
</script>
